import React from "react";

import { Col, Row } from "antd";
import styled from "styled-components";

function TermsOfService() {
  return (
    <CenterRow>
      <Col md={12}>
        <H1>TERMS OF USE</H1>
        <P>
          PLEASE READ. YOUR USE OF THE SINDHIPLEX OTT PLATFORM IS SUBJECT TO
          THESE TERMS OF USE AND OUR PRIVACY POLICY. BY USING THE SITE, YOU
          CONSENT TO THE COLLECTION AND USE OF YOUR DATA IN ACCORDANCE WITH OUR
          PRIVACY POLICY
        </P>
        <P>
          These Terms of Use (“Terms") applies to the Sai Digital Broadcast
          Media Private Limited (“SindhiPlex”) digital platforms including
          without limitation www.SindhiPlex.com and other related websites,
          mobile applications and other online features that link to this Terms
          (each a “Site(s)/ App(s)”). These Terms shall apply to the websites,
          web pages, digital platforms, interactive features, applications,
          widgets, blogs, social networks, etc., alternative reality worlds or
          features, or other online or wireless offerings that post a link to
          these Terms, whether accessed via computer, mobile device or other
          technology, manner or means. The Site(s)/ App(s) are offered by
          SindhiPlex or other companies associated with SindhiPlex
          (collectively, “SindhiPlex,” or “we”, “us”, “our”). These Terms govern
          your rights and responsibilities in connection with the particular
          Site(s)/ App(s) that you are using. The term Site(s)/ App(s) includes
          the content on that Site(s)/ App(s), all of our services provided on
          or through that Site(s)/ App(s) (the “Services”), and any software
          that we make available on or through that Site(s)/ App(s) (the
          ”Software”), unless otherwise specified. You “use” the Site(s)/ App(s)
          anytime you access (via computer, mobile device or other devices,
          etc.), view, link to or from, or otherwise interact or communicate
          with or connect to, the Site(s)/ App(s) (or any parts thereof) or
          interact or communicate with other users through the Site(s)/ App(s)
          (including, without limitation, on message boards, chat rooms and/or
          other communities established on the Site(s)/ App(s)). Your use of the
          Site(s)/ App(s) (or any part thereof) signifies your agreement to be
          bound by these Terms and the SindhiPlex privacy policy (the “Privacy
          Policy”), which is hereby incorporated by this reference into these
          Terms. These Terms constitute a legally binding agreement between you
          and SindhiPlex regarding your use of the Site(s)/ App(s) and the
          services that allow for the sharing of such content with third
          party(ies) and access to and/ or view the video, audio, and other
          content through such Site(s)/ App(s). Do not use the Site(s)/ App(s)
          if you do not agree with any of the terms contained herein and in the
          Privacy Policy. By accessing the Site(s)/ App(s) and/ or Services you
          agree to be bound by these Terms. You hereby represent and warrant to
          SindhiPlex that you are at least eighteen (18) years of age and/or
          otherwise capable of entering into and performing legal agreements,
          and that you agree to be bound by the following terms and conditions.
          While individuals under the age of 18 may utilize the Site Service,
          they may do so only with the supervision and prior consent/ permission
          of a parent or legal guardian, under such person's account and
          otherwise subject to these Terms. In certain jurisdictions, the age of
          majority may be older than 18, in which case you must satisfy that age
          in order to become a member / utilize the Services. Given the nature
          of the internet, even though the Site(s)/ App(s) is/ are ordinarily
          (but not always) targeted to Indian residents, it may be accessed in
          other parts of the world. If you are not an Indian resident and yet
          use the Site(s)/ App(s), you acknowledge, understand and agree that
          you are doing so on your own initiative and at your own risk and that
          it is your responsibility (and not ours) to make sure that your use of
          the Site(s)/ App(s) complies with all applicable local laws in the
          jurisdiction from where you access or use the Site(s)/ App(s).
        </P>
        <P>
          IF YOU ARE ACCESSING THE SITE(S)/ APP(S), BY SUBMITTING YOUR
          PERSONALLY IDENTIFIABLE INFORMATION ON THE SITE(S)/ APP(S), YOU
          EXPRESSLY CONSENT TO THE TRANSFER OF SUCH DATA TO INDIA, AND TO THE
          PROCESSING OF SUCH DATA ON SINDHIPLEX'S (OR ITS DESIGNATED VENDORS)
          SERVERS, WHERE YOUR DATA WILL BE GOVERNED BY RESPECTIVE LAWS THAT MAY
          PROVIDE A LOWER (OR DIFFERENT) LEVEL OF DATA PROTECTION THAN YOUR
          COUNTRY.
        </P>
        <P>
          SindhiPlex reserves the right at its sole discretion, to change,
          modify and/or add to these Terms or to the Privacy Policy, in whole or
          in part, at any time. Changes to these Terms or the Privacy Policy
          will be effective when posted. You agree to review these Terms and the
          Privacy Policy periodically to become aware of any changes. Your use
          of the Site(s)/ App(s) or any part thereof after any changes to these
          Terms or the Privacy Policy are posted will be considered acceptance
          of those changes and will constitute your agreement to be bound
          thereby. If you object to any such changes, your sole recourse will be
          to stop using the Site(s)/ App(s).
        </P>
        <P>1. OWNERSHIP; RESTRICTIONS ON USE</P>
        <P>
          The content of the Site(s)/ App(s) and all copyrights, patents,
          trademarks, service marks, trade names and all other intellectual
          property rights therein are owned by SindhiPlex and/or its licensors
          or group companies and are protected by applicable Indian and
          international copyright and other intellectual property laws. You
          acknowledge, understand and agree that you shall not have, nor be
          entitled to claim, any rights in and to the Site(s)/ App(s) content/
          Services and/or any portion thereof. You agree not to copy, reproduce,
          duplicate, stream, capture, access through technology or means other
          than those provided on the Site(s)/ App(s), perform, transfer, sell,
          resell, download, upload, archive, license to others, edit, modify,
          manipulate, create derivative works from or based upon (including,
          without limitation, mash-ups, montages, wallpapers, ringtones,
          greeting cards, T-shirts or other merchandise), publish, republish,
          post, transmit, publicly display, frame, link from or to, distribute,
          share, embed, translate, decompile, reverse engineer, incorporate into
          any hardware or software application, use for commercial purposes, or
          otherwise use or exploit the Site(s)/ App(s) or any component part
          thereof. Any of the unauthorized uses referred to above would
          constitute an infringement of the copyrights and other proprietary
          rights of SindhiPlex and/or its licensors and a violation of these
          Terms and may subject you to civil and/or criminal liability under
          applicable laws.
        </P>
        <P>2. LICENSES GRANTED TO YOU</P>
        <P>
          Subject to these Terms (including, without limitation, the YOUR
          RESPONSIBILITIES section set forth below), we hereby grant you, if and
          only to the extent the necessary functionality is provided to you on
          or through the Site(s)/ App(s), the following limited, revocable,
          non-exclusive, non-transferable, non-assignable, worldwide,
          royalty-free rights and licenses (“License”):
        </P>
        <P>
          2.1 The License to access, view and otherwise use the Site(s)/ App(s)
          (including, without limitation any Services provided on or through the
          Site(s)/ App(s)) for your personal (non-commercial), lawful use only,
          as intended through the customary and authorized functionality of the
          Site(s)/ App(s);
        </P>
        <P>
          2.2 The License to stream Site(s)/ App(s) content using any of the
          widgets and/or other digital streaming internet video players, if any,
          provided on the Site(s)/ App(s) (any such widget or other digital
          streaming internet video player referred to as a “Site Widget/ Video
          Player”) for your personal, lawful use only;
        </P>
        <P>
          2.3 The License to cut and paste certain code expressly made available
          to you through the Site(s)/ App(s) (whether such functionality is
          designated as “sharing” functionality or not) in order to embed,
          re-publish, maintain, and/or display the specific Site(s)/ App(s)
          content to which such code relates on your own personal, customized
          social networking web page(s), web blog(s), or microblog(s)
          (collectively, your “Personal Social Media”), and/or, if the Site(s)/
          App(s) provides “widget grabbing and embedding” functionality, to
          “grab” a Site Widget and embed, re-publish, maintain, and/or display
          such Site Widget on your Personal Social Media;
        </P>
        <P>
          2.4 The License to cut and paste certain code expressly made available
          to you through the Site, and/or if the Site provides “widget grabbing
          and embedding” functionality, to “grab” a Site Widget, to forward, as
          applicable, such code and/or Site Widget to your friends, so that they
          can view the Site content contained therein, and/or if, they so
          desire, so that they themselves may embed the forwarded piece of code
          or Site Widget on their own Personal Social Media or re-forward it to
          their own friends;
        </P>
        <P>
          2.5 If the Site(s)/ App(s) enables you to download Software, the
          License to install and use one copy of the Software on your personal
          computer system, mobile or other permitted device; provided, however,
          that you understand and agree that (i) by allowing you to download the
          Software, SindhiPlex does not transfer title to the Software to you
          (i.e., you own the medium on which the Software is recorded, but the
          Software's owner (which may be SindhiPlex and/or its third party
          Software licensor) will retain full and complete title to such
          Software; (ii) you shall not copy, modify, adapt, translate into any
          language, distribute, or create derivative works based on the Software
          without the prior written consent of SindhiPlex; (iii) you shall not
          assign, rent, lease, or lend the Software to any person or entity and
          any attempt by you to sublicense, transfer, or assign the Software
          will be void and of no effect; (iv) you shall not decompile,
          disassemble, reverse engineer, or attempt to reconstruct, identify, or
          discover any source code, underlying ideas, underlying user interface
          techniques, or algorithms of the Software by any means whatsoever,
          except to the extent the foregoing restriction is prohibited by
          applicable law.
        </P>
        <P>3. YOUR RESPONSIBILITIES</P>
        <P>
          Your rights under any one or more of the Licenses set forth above are
          conditioned upon your compliance with each of the following:
        </P>
        <P>
          3.1 Subject to availability of these functionalities you will not
          create or Submit (as defined below) a User Submission that is
          unlawful, pornographic, obscene, defamatory, libelous, threatening,
          discriminatory, harassing, bullying, vulgar, indecent, profane,
          hateful, racially, culturally or ethnically offensive, or that
          encourages criminal conduct, or that gives rise, or potentially gives
          rise, to civil or criminal liability, or that violates any applicable
          laws, rules, regulations or (“Applicable Laws”), or that infringes or
          violates other parties' intellectual property, publicity, or privacy
          rights or links to infringing or unauthorized content;
        </P>
        <P>
          3.2 You will not embed, re-publish, maintain and/or display any
          Site(s)/ App(s) content (including, without limitation, any of your
          own User Submissions) on any Personal Social Media or other web site
          or other internet location that ordinarily contains or hosts content
          that is unlawful, pornographic, obscene, defamatory, libelous,
          threatening, discriminatory, harassing, bullying, vulgar, indecent,
          profane, hateful, racially, culturally or ethnically offensive, or
          that encourages criminal conduct, or that gives rise, or potentially
          gives rise, to civil or criminal liability, or that violates any
          Applicable Laws, or that infringes or violates other parties'
          intellectual property rights or links to infringing or unauthorized
          content;
        </P>
        <P>
          3.3 You will not send spam, chain letters, pyramid schemes or any
          other unsolicited or unauthorized advertising or promotional
          materials, or harass, bully, stalk, harm, or otherwise cause mental or
          physical distress or injury to anyone, or defame or disparage someone,
          or engage in any other unlawful or objectionable conduct while using
          the Site;
        </P>
        <P>
          3.4 You will provide accurate, current and complete information about
          you or anyone else, and you will not provide false information about
          you or anyone else (including, without limitation, when you are being
          asked on the Site to provide accurate information about your age), and
          you will not impersonate or appear to impersonate anyone else or
          otherwise misrepresent your affiliation with any person or entity;
        </P>
        <P>
          3.5 You will not use any one or more of the Licenses granted to you
          (and any associated functionality) to collect, obtain, compile,
          gather, transmit, reproduce, delete, revise, view, display, forward,
          any material or information, whether personally identifiable or not,
          posted by or concerning any other user of the Site, unless you have
          obtained prior permission from such user to do so;
        </P>
        <P>
          3.6 You will not interfere with or disrupt, or attempt to interfere
          with or disrupt, the operation of the Site(s)/ App(s) (or any parts
          thereof);
        </P>
        <P>
          3.7 You will abide by all copyright notices, information, and
          restrictions contained in or associated with any of the Site(s)/
          App(s) content;
        </P>
        <P>
          3.8 You will not remove, alter, interfere with or circumvent any
          copyright, trademark, watermark, or other proprietary notices
          marked/displayed on Site(s)/ App(s) content, Software or Services;
        </P>
        <P>
          3.9 You will not remove, alter, interfere with or circumvent any
          digital rights management mechanism, device or other content
          protection or access control measure (including, without limitation
          geo-filtering and/or encryption, etc.) associated with Site content;
        </P>
        <P>
          3.10 You will not use any of the rights granted to you or any of the
          Site(s)/ App(s) content or Services in a manner that suggests an
          association with any of SindhiPlex 's products, services or brands,
          unless otherwise specifically permitted by SindhiPlex;
        </P>
        <P>
          3.11 You will not use bots, spiders, offline readers or other
          automated systems to access or use the Site(s)/ App(s) in a manner
          that sends more request messages to the Site(s)/ App(s)'s servers in a
          given period of time than a human can reasonably produce in the same
          period by using a conventional web browser, unless you are a lawfully
          operating a public search engine, in which case (subject to SindhiPlex
          's full discretion to revoke this exception at any time), you may use
          spiders to index materials from the Site for the sole purpose of
          creating publicly available searchable indices (but not caches or
          archives) of such materials;
        </P>
        <P>
          3.12 You will not knowingly transmit any material that contains
          adware, malware, spyware, software viruses, time-bombs, cancel-bots,
          worms, trojan horses, or any other computer code, files, or programs
          designed to interrupt, destroy, or limit the functionality of any
          computer software or hardware or telecommunications equipment;
        </P>
        <P>
          3.13 You will not do anything that is likely to adversely affect or
          reflect negatively upon or harm the goodwill or reputation of
          SindhiPlex or any of its affiliates or group companies, or the
          Site(s)/ App(s), or any of the content running or being promoted on
          the Site(s)/ App(s) (including, without limitation, any movie,
          television program or other initiative);
        </P>
        <P>
          3.14 You will not do anything on the Site(s)/ App(s) that would
          prevent other users' access to or use of the Site(s)/ App(s) or any
          part thereof;
        </P>
        <P>
          3.15 You will be responsible for maintaining the confidentially of any
          username or password associated with access to the Site(s)/ App(s)
          (including, without limitation, in those instances in which the
          Site(s)/ App(s) uses the account creation mechanism and management of
          a third party's social network, website or device), and you are solely
          responsible for all activities that occur under your username and/or
          password; and
        </P>
        <P>
          3.16 You will use the Site(s)/ App(s) and the Licenses always in
          compliance with these Terms and all Applicable Laws.
        </P>
        <P>
          4. USER SUBMISSIONS; GRANT OF LICENSE TO SINDHIPLEX; YOUR WARRANTIES
          AND REPRESENTATIONS
        </P>
        <P>
          When you submit (or attempt to submit) your User Submissions on or by
          means of the Site(s)/ App(s), you automatically grant to SindhiPlex
          and its affiliates, group companies an irrevocable, perpetual,
          worldwide, unconditional, unrestricted, fully-paid, royalty-free,
          transferable, fully sub-licenseable to multiple tiers of sublicensees,
          non-exclusive right and license (the “SindhiPlex License”) to display,
          publicly perform, distribute (including, without limitation, through
          third-party web sites, blogs, microblogs and other social networking
          media and internet and mobile resources and applications etc.), store,
          transcode, host, cache, maintain, broadcast, webcast, mobile-cast,
          transmit, distribute, tag, track, reproduce, edit, modify, format,
          re-format, link to and from, translate, delete, create derivative
          works, combine with other content, categorize, and/or otherwise use,
          reuse and/or otherwise exploit your User Submissions (or any portions
          or derivative works thereof) and any names, likenesses, voices,
          images, digital recordings, performances, and other ideas, concepts,
          feedback and other materials included in your User Submissions, in any
          manner, medium or content delivery technology now known or hereinafter
          devised, for any purpose, as determined solely in SindhiPlex's (or its
          authorized designees/licensees') sole discretion, without the
          requirement for any compensation to you or anyone else, or the need to
          require additional consent from you or anyone else, or the need to
          provide prior notification or any credit to you or anyone else. The
          SindhiPlex License shall survive in perpetuity in accordance with its
          terms regardless of whether you stop using the Site or SindhiPlex
          terminates your access to the Site(s)/ App(s). NOTHING CONTAINED
          HEREIN SHALL BE DEEMED TO TRANSFER TO SINDHIPLEX OR TO ANY OF ITS
          AFFILIATES, ANY OWNERSHIP RIGHTS IN AND TO YOUR USER SUBMISSIONS,
          PROVIDED, HOWEVER, THAT SINDHIPLEX AND/OR ITS AFFILIATES AND/OR ITS
          LICENSORS WILL RETAIN SOLE AND EXCLUSIVE OWNERSHIP OF ANY OF THE
          SINDHIPLEX LICENSED ASSETS (IF ANY) INCLUDED IN YOUR USER SUBMISSIONS.
          EVEN THOUGH BY SUBMITTING YOUR USER SUBMISSIONS YOU ARE GRANTING TO
          SINDHIPLEX THE BROAD RIGHTS AND LICENSES SET FORTH ABOVE, YOU
          ACKNOWLEDGE AND UNDERSTAND THAT NOTHING CONTAINED HEREIN SHALL
          OBLIGATE SINDHIPLEX AND/OR ANY OF ITS AFFILIATES OR AUTHORIZED
          DESIGNEES TO HOST, DISTRIBUTE, DISPLAY OR OTHERWISE EXPLOIT ANY OF
          YOUR USER SUBMISSIONS OR EXERCISE ANY OTHER RIGHTS UNDER THE
          SINDHIPLEX LICENSE. You acknowledge and agree that: (a) you have no
          expectation of confidentiality of any nature with respect to any of
          your User Submissions, and (b) SindhiPlex and/or any of its affiliates
          may already have projects under consideration that are similar to your
          User Submissions or may independently develop projects that are
          similar to your User Submissions, or other User Submissions
          transmitted by other users of the Site may be similar or the same as
          your User Submissions. Because of the viral nature of social media,
          you should not submit any content or information to SindhiPlex that
          you do not want to be viewed and “shared” or otherwise distributed by
          others. In connection with all of the User Submissions you Submit to
          the Site, and any other activities that you conduct on or by means of
          the Site, you represent, warrant and covenant that: (i) the User
          Submissions are original to you (in other words, that you own all
          rights in and to your User Submissions) or, alternatively, that you
          have acquired all necessary rights in your User Submissions to enable
          you to grant to SindhiPlex the SindhiPlex License; (ii) you have paid
          or satisfied and will pay or satisfy in full all license fees,
          clearance fees, royalties, and any other financial or third party
          obligations of any kind, if any, arising from any use or exploitation
          of your User Submissions, and SindhiPlex will not be responsible for
          such obligations; (iii) your User Submissions do not infringe the
          intellectual property rights, privacy rights, publicity rights, or any
          other legal or moral rights of any third party; (iv) neither your User
          Submissions, nor any of the other activities that you undertake on the
          Site(s)/ App(s), are or will at any time be: defamatory or disparaging
          of any individual or entity, unlawful, pornographic, obscene,
          threatening, vulgar, indecent, profane, hateful, sexually explicit or
          sexually suggestive, intended to bully or harass or stalk, or
          resulting in bullying, harassing, or stalking, racially or ethnically
          or culturally offensive, or otherwise objectionable or encouraging of
          criminal conduct, or giving rise, or potentially giving rise, to civil
          or criminal liability, or linking to infringing or unauthorized
          content, or transmitting unsolicited advertising materials, or
          intended to result in, or resulting in, any mental or physical
          distress or injury to any person, violate of any Applicable Laws or
          these Terms, or violate of any person's publicity or privacy rights;
          and (v) your User Submissions are not intended by you to benefit any
          commercial enterprise associated with you or any third party. You
          agree to keep all records necessary to establish that your User
          Submissions do not violate any of the foregoing representations and
          warranties and to make such records available to SindhiPlex upon
          SindhiPlex 's request. SindhiPlex is not responsible for any loss,
          theft or damage of any kind to any User Submissions. You acknowledge
          that SindhiPlex does not as a matter of its ordinary practice
          pre-screen any User Submissions submitted by you or other users of the
          Site but that SindhiPlex (and/or any of its authorized designees) has
          the right to and may pre-screen or review after initial submission any
          of the User Submissions for a variety of reasons, including, without
          limitation, for compliance with these Terms or Applicable Laws, or if
          otherwise desired or necessary, as determined by SindhiPlex in its
          sole discretion. SindhiPlex shall have the right in its sole
          discretion to refuse, remove, edit, or disable any of your User
          Submissions (and/or any other Site users' User Submissions) that
          violate these Terms or are otherwise objectionable, as determined by
          SindhiPlex in its sole discretion, as well as terminate your and/or
          any Site user's continued access to and/or other use of the Site
          (including, without limitation, access to and/or use of any of the
          Services, features or functionality available on or through the Site).
          SindhiPlex does not control or endorse any User Submissions, and any
          User Submissions submitted to the Site do not reflect the opinions,
          views or policies of SindhiPlex or any of its affiliates. SindhiPlex
          disclaims any and all liability in connection with any and all User
          Submissions, and you agree to waive any legal or equitable rights or
          remedies you may have against SindhiPlex or any of its affiliates with
          respect to such User Submissions. If you remove or delete any of your
          User Submissions from the Site, that User Submission will be removed
          from the Site within a reasonable period of time. Nonetheless, a User
          Submission that you remove may still be available to third parties who
          previously acquired the link to that User Submission and SindhiPlex
          may maintain copies for archival purposes. SindhiPlex reserves the
          right to display advertisements in connection with your User
          Submissions and to use your User Submissions to advertise and promote
          your User Submissions or the Site (in whole or in part) or any of
          SindhiPlex products or services. You acknowledge and agree that you
          have no right or interest in any of the revenue generated from such
          advertisements. These provisions shall remain in full force and effect
          in perpetuity notwithstanding any termination of your use of the Site.
        </P>
        <P>
          Users hereby acknowledge that certain content on the Site(s)/ App(s)
          is for use solely by responsible adults over the age of 18 or the age
          of consent in the jurisdiction from which it is being accessed. There
          are various genres of content suitable for the consumption by the
          users below the age of 18 years and also for users above 18 years of
          age, who have attained the age of majority. Should the user choose to
          access such content intended for the consumption by the users above
          the age of 18 years then, , such user shall be making the following
          representations:
        </P>
        <P>
          That the user has attained the age of majority, or at least 18 years
          of age, and has the legal right to access and / or possess content
          meant for adults;
        </P>
        <P>
          That the user has voluntarily chosen to access such content, because
          he / she wants to view the same and does not find the said content to
          be offensive or objectionable;
        </P>
        <P>
          That by viewing any part / portion of content intended for the
          consumption by the users above the age of 18 years available on the
          application/website, the user agrees that the user shall not hold the
          owners of the application/website SINDHIPLEX, its directors, or its
          employees responsible for any said materials;
        </P>
        <P>
          That the user will exit this Site(s)/ App(s) immediately should he /
          she be in anyway offended by the adult nature of the content;
        </P>
        <P>
          That the user understands and agrees to abide by the standards and
          laws of India or the jurisdiction from which it is being accessed.
        </P>
        <P>5. DISCLAIMER OF WARRANTIES</P>
        <P>
          YOU EXPRESSLY AGREE THAT YOUR USE OF THE SITE(S)/ APP(S) IS AT YOUR
          SOLE RISK. THE SITE(S)/ APP(S) (AND ANY PORTION OF THE SITE(S)/
          APP(S)) IS PROVIDED “AS IS” “WITH ALL FAULTS” AND “AS AVAILABLE.” TO
          THE FULL EXTENT PERMITTED BY LAW, SINDHIPLEX AND ITS AFFILIATES
          EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
          OR IMPLIED (INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR PURPOSE AND
          NON-INFRINGEMENT). Neither SindhiPlex nor any of its affiliates make
          any warranties or representations about the accuracy or completeness
          of content available on or through the Site(s)/ App(s) or the content
          of any web sites, Personal Social Media or other internet or mobile
          resources linked to the Site(s)/ App(s) and assume no liability or
          responsibility for any: (i) errors, mistakes, or inaccuracies of
          content; (ii) personal injury or property damage, of any nature
          whatsoever, resulting from your access to or use of the Site(s)/
          App(s) (or any parts thereof); (iii) any unauthorized access to or use
          of our secure servers and/or any and all personal information stored
          therein; (iv) any interruption or cessation of transmission to or from
          the Site(s)/ App(s); (v) any bugs, viruses, Trojan horses, or the like
          which may be transmitted to or through the Site(s)/ App(s) (or any
          parts thereof) by any third party; and/or (vi) for any loss or damage
          of any kind incurred as a result of the use of any User Submissions
          and/or other Site(s)/ App(s) content posted, shared, forwarded,
          emailed, transmitted, or otherwise made available on or by means of
          the Site(s)/ App(s) and/or otherwise through your or any other
          Site(s)/ App(s) users' exercise of any rights under any of the
          Licenses granted by SindhiPlex herein. SindhiPlex reserves the right,
          in its sole and exclusive discretion, to change, modify, add, remove
          or disable access to any portion of the Site(s)/ App(s) (including,
          without limitation any of the Services).
        </P>
        <P>6. LIMITATION OF LIABILITY</P>
        <P>
          YOU UNDERSTAND THAT TO THE EXTENT PERMITTED UNDER APPLICABLE LAWS, IN
          NO EVENT WILL SINDHIPLEX OR ANY OF ITS AFFILIATES OR ANY OF THEIR
          RESPECTIVE OFFICERS, EMPLOYEES, DIRECTORS, SHAREHOLDERS, AGENTS, OR
          LICENSORS BE LIABLE TO YOU OR ANYONE ELSE UNDER ANY THEORY OF
          LIABILITY (WHETHER IN CONTRACT, TORT, STATUTORY, OR OTHERWISE) FOR ANY
          INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES,
          INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF REVENUES, PROFITS,
          GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF SUCH PARTIES
          WERE ADVISED OF, KNEW OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF
          SUCH DAMAGES), RESULTING FROM YOUR USE (OR THE USE OF ANYONE USING AN
          ACCOUNT REGISTERED TO YOU) OF THE SITE(S)/APP(S) OR ANY PARTS THEREOF.
        </P>
        <P>
          YOU UNDERSTAND AND AGREE THAT SINDHIPLEX DOES NOT COMMIT TO A MINUMUM
          UPTIME AND IS NOT IN ANYWAY LIABLE TO INDEMNIFY/ COMPENSATE/ REFUND
          YOU FOR ANY LOSS/ HARM/ DAMAGE CAUSED THEREBY.
        </P>
        <P>
          SindhiPlex Site(s)/App(s) enables streaming of content only through
          compatible devices. This software may vary by device and medium, and
          functionalities may also differ between devices. SindhiPlex shall
          endeavor but not be committed and liable to ensure the compatibility
          and/ functionality of the Site(s)/ App(s) with all your devices. In
          the event of failure of the same, you can contact the customer support
          and SindhiPlex shall use its best possible efforts to extend such
          compatibility. However, in no event shall you hold SindhiPlex liable
          for the same.
        </P>
        <P>7. INDEMNIFICATION</P>
        <P>
          You agree to indemnify and hold harmless SindhiPlex and its affiliates
          and its and their respective officers, directors, employees,
          representatives, licensees, authorized designees, successors and
          assigns from any and all claims, liabilities, damages, losses, costs,
          expenses, fees (including reasonable outside attorneys' fees and
          costs) that such parties may incur as a result of or arising from (i)
          any of your User Submissions; (ii) your exercise of any of your rights
          granted under the Licenses without your compliance with your
          responsibilities set forth in these Terms; (ii) the breach of any of
          your warranties, representations, covenants, responsibilities or other
          obligations set forth in these Terms; (iii) your violation of any
          person's intellectual property, privacy, publicity or other right;
          (iv) the violation of any Applicable Laws and/or these Terms by you
          and/or anyone using your registered account to access and/or otherwise
          use the Site (in whole or in part); or (v) your willful misconduct or
          the willful misconduct of anyone accessing the Site through your
          registered account, in connection with your (and/or such other
          person's) use of the Site (in whole or in part). SindhiPlex reserves
          the right to assume the exclusive defense and control of any matter
          otherwise subject to indemnification by you, in which event you will
          cooperate with SindhiPlex in asserting any available defenses. This
          provision shall remain in full force and effect notwithstanding any
          termination of your use of the Site.
        </P>
        <P>
          8. COPYRIGHT INFRINGEMENT NOTIFICATION; DESIGNATED COPYRIGHT AGENT
        </P>
        <P>
          SindhiPlex respects the intellectual property rights of others. Upon
          proper notice, SindhiPlex will remove User Submissions (and any other
          Site(s)/ App(s) content) that violate copyright law. As per the
          provisions of Information Technology Act, 2000 and any amendments made
          thereto along with the rules framed there under and the provisions of
          the Copyright Act, 1957 along with all the amendments made thereto and
          rules framed there under, SindhiPlex shall remove upon receiving
          written notification of claimed copyright infringement on the Site(s)/
          App(s) and for processing such claims in accordance with such law. If
          you believe a work has been copied in a way that constitutes copyright
          infringement, please send a notification of claimed infringement (an
          “Infringement Notification”) that contains all the following
          information: (i) identification of the copyrighted work claimed to
          have been infringed; (ii) identification of the claimed infringing
          material and information reasonably sufficient to permit SindhiPlex to
          locate the material on the Site(s)/ App(s); (iii) information
          reasonably sufficient to permit SindhiPlex to contact you, such as an
          address, telephone number, and, if available, an e-mail address; (iv)
          a statement by you that you have a good faith belief that the disputed
          use is not authorized by the copyright owner, its agent, or the law;
          (v) a statement by you, made under penalty of perjury, that the above
          information in your notification is accurate and that you are the
          copyright owner or authorized to act on the copyright owner's behalf;
          and (vi) your physical signature.
        </P>
        <P>Legal Department</P>
        <P>Sai Digital Broadcast Media Private Limited</P>
        <P>B/444, 4th Floor, Oshiwara Industrial Centre Premises,</P>
        <P>CHSL, New Link Road,Goregaon (W), Mumbai,</P>
        <P>Maharashtra, India 400104</P>
        <P>
          By submitting an Infringement Notification, you acknowledge and agree
          that SindhiPlex may forward your Infringement Notification and any
          related communications to any users who posted the material identified
          in such notice.
        </P>
        <P>9. GOVERNING LAW AND JURISDICTION</P>
        <P>
          The relationship between you and SindhiPlex shall be governed by the
          laws of India without regard to its conflict of law provisions and for
          resolution of any dispute arising out of your use of the Services.
          Notwithstanding the foregoing, you agree that (i) SindhiPlex has the
          right to bring any proceedings before any court/forum of competent
          jurisdiction and you irrevocably submit to the jurisdiction of such
          courts or forum and (ii) any proceeding brought by you shall be
          exclusively before the courts at Mumbai, India.
        </P>
        <P>10. SEVERABILITY; WAIVER</P>
        <P>
          If any provision of these Terms shall be held unlawful, void, or for
          any reason unenforceable, then that provision shall be deemed
          severable from these Terms and shall not affect the validity and
          enforceability of any remaining provisions. The failure of SindhiPlex
          to exercise or enforce any right or provision of these Terms shall not
          constitute a waiver of such right or provision. You agree that
          regardless of any statute or law to the contrary, any claim or cause
          of action arising out of or related to the use of the Site(s)/ App(s)
          or these Terms must be filed within one (1) year after such claim or
          cause of action arose or be forever barred. This provision shall
          remain in full force and effect notwithstanding any termination of
          your use of the Site(s)/ App(s).
        </P>
        <P>11. TERMINATION</P>
        <P>
          You understand and agree that SindhiPlex may, in its sole discretion
          and at any time, terminate or temporarily suspend your rights under
          any of the Licenses and otherwise your access to the Site(s)/ App(s),
          and discard, remove, and/or disable or deactivate any or all of your
          User Submissions that you submitted to or by means of the Site(s)/
          App(s) and/or any other information and data that you (and/or anyone
          else) may have provided to or by means of the Site(s)/ App(s), for any
          reason. SindhiPlex may also, in its sole discretion and at any time,
          discontinue the Site(s)/ App(s) or any parts thereof or limit or
          restrict any user access thereto, for any reason, with or without
          notice. YOU UNDERSTAND AND AGREE THAT SINDHIPLEX MAY TAKE ANY ONE OR
          MORE OF THESE ACTIONS WITHOUT ANY NOTICE TO YOU, PRIOR OR OTHERWISE,
          AND YOU UNDERSTAND AND AGREE THAT NEITHER SINDHIPLEX NOR ANY OF ITS
          AFFILIATES SHALL HAVE ANY LIABILITY TO YOU OR TO ANY OTHER PERSON FOR
          ANY TERMINATION OF YOUR (OR ANYONE ELSE'S) ACCESS TO THE SITE(S)/
          APP(S) OR PARTS THEREOF; AND/OR THE REMOVAL, DISCARDING, DISABLING OR
          DEACTIVATION OF ANY OF YOUR USER SUBMISSIONS (OR THE USER SUBMISSIONS
          OF ANYONE ELSE) OR THE REMOVAL, DISCARDING, DISABLING OR DEACTIVATION
          OF ANY OTHER INFORMATION OR DATA THAT YOU (OR ANYONE ELSE) MAY HAVE
          PROVIDED ON OR BY MEANS OF THE SITE(S)/ APP(S). YOU MAY DISCONTINUE
          YOUR ACCESS TO, USE OR PARTICIPATION ON OR BY MEANS OF THE SITE(S)/
          APP(S) AT ANY TIME.
        </P>
        <P>12. INJUNCTIVE RELIEF</P>
        <P>
          Notwithstanding anything to the contrary herein, you hereby
          irrevocably waive any right or remedy to seek and/or obtain injunctive
          or other equitable relief or any order with respect to, and/or to
          enjoin or restrain or otherwise impair in any manner, the production,
          distribution, exhibition or other exploitation of any SindhiPlex or
          any SindhiPlex affiliate-related motion picture, production or
          project, or the use, publication or dissemination of any advertising
          or promotion in connection with such motion picture, production or
          project. This provision shall remain in full force and effect
          notwithstanding any termination of your use of the Site(s)/ App(s).
        </P>
        <P>13. CHANGES IN SITE OWNERSHIP</P>
        <P>
          You will be deemed to have consented to the disclosure to, and use by,
          a subsequent owner or operator of the Site(s)/ App(s), of any
          information about you contained in the applicable SindhiPlex database,
          to the extent SindhiPlex assigns its rights and obligations regarding
          such information in connection with a merger, acquisition, or sale of
          all or some of our assets, or in connection with a merger, acquisition
          or sale of all or some assets related to a particular Site(s)/ App(s),
          to a subsequent owner or operator. In the event of such a merger,
          acquisition, or sale, your continued use of the Site(s)/ App(s)
          signifies your agreement to be bound by the terms of use and privacy
          statement of the Site(s)/ App's(s) subsequent owner or operator. This
          provision shall remain in full force and effect notwithstanding any
          termination of your use of the Site(s)/ App(s).
        </P>
        <P>14. SPECIAL RULES FOR SINDHIPLEX WEBSITE/ APP SERVICE</P>
        <P>
          SINDHIPLEX SITE(S)/ APP(S) SERVICE, REGISTRATION AND ACCESS TO USE
        </P>
        <P>
          The SindhiPlex Site(s)/ App(s) Service is an online platform service
          providing its members with various contents by way of streaming over
          the internet to the SindhiPlex Site(s)/ App(s) on various devices and
          platforms which will include mobile and similar other handheld devices
          and by way mobile applications.
        </P>
        <P>Registration</P>
        <P>
          To register for the SindhiPlex Site(s)/ App(s) Service you shall be
          required to do the following:
        </P>
        <P>
          Open an account by completing the registration process (i.e. by
          providing us with current, complete and accurate information as
          prompted by the applicable registration form). You also will choose a
          password and a user name.
        </P>
        <P>
          You are entirely responsible for maintaining the confidentiality of
          your password and account. In particular, as a parent or legal
          guardian, you acknowledge and assume sole responsibility to ensure
          that content which is meant for mature audiences (i.e, above the age
          of majority) is not accessed by children. Hence, you may not share
          your log in credentials with your children.
        </P>
        <P>
          You expressly agree to absolve SindhiPlex and/or the SindhiPlex
          Site(s)/ App(s) Service of any responsibility / liability in this
          regard.
        </P>
        <P>Facebook Connect:</P>
        <P>
          You may also register for the SindhiPlex Site(s)/ App(s) Service by
          using your Facebook username and password. Using Facebook Connect
          allows SindhiPlex to personalize and enhance your experience while
          using the SindhiPlex Site(s)/ App(s) Service, based on your personal
          information, profile, likes, and other relevant information. When you
          use this feature, you expressly consent to information about your
          activity on the SindhiPlex Site(s)/ App(s) Service (i.e. what you have
          watched, what you have liked, ratings given by you, etc.) being
          continuously released and automatically posted on your Facebook
          account (which has been used to log in) and made available to your
          friends on Facebook. You may control the information being shared
          through Facebook Connect by changing your account / privacy settings.
          By registering, you agree to the Terms stated herein and in addition
          to any other specific terms which shall be posted at an appropriate
          location of the Site(s)/ App(s).
        </P>
        <P>15. SUBSCRIPTION</P>
        <P>
          To be able to view certain premium content (“Premium Content”), you
          will be required to subscribe to any one subscription plan (“Plan”) of
          the SindhiPlex Site(s)/ App(s) Service viz., the plans available on
          the Site(s)/ App(s) to be able to access the services which shall be
          entitled to full fee flexibility, solely at the discretion of
          SindhiPlex.
        </P>
        <P>
          You may be required to subscribe to the SindhiPlex Site(s)/ App(s)
          Service and such subscription in a particular geographical territory
          that shall be valid for that territory only and shall not
          automatically entitle you to access your account from a different
          geographical territory, unless specifically permitted by SindhiPlex.
          You will be required to re-subscribe to the SindhiPlex Site(s)/ App(s)
          Services in the event that you wish to use the services from a
          different territory other than the one from where you originally
          subscribed to the SindhiPlex Site(s)/ App(s) Service.
        </P>
        <P>16. ACCESS TO USE:</P>
        <P>
          To access the SindhiPlex Site(s)/ App(s) Service, you will be asked to
          enter your individual user name and password, as chosen by you during
          your registration. Therefore, SindhiPlex does not permit any of the
          following:-
        </P>
        <P>(1) Any other person sharing your account and password;</P>
        <P>
          (2) Any part of the Site(s)/ App(s) being cached in proxy servers and
          accessed by individuals who have not registered with SindhiPlex as
          users of the Site(s)/ App(s).
        </P>
        <P>
          Access through a single account being made available to multiple
          devices, being limited to a maximum of 1 (One) devices at any given
          time, (subject to change without prior intimation) on a network.
        </P>
        <P>
          If SindhiPlex reasonably believes that an account and password is
          being used / misused in any manner, SindhiPlex reserves the right to
          cancel access rights immediately without notice, and block access to
          all users from that IP address. Furthermore, you shall be entirely
          responsible for any and all activities that occur under your account.
          You agree to notify SindhiPlex immediately of any unauthorized use of
          your account or any other breach of security. SindhiPlex will not be
          liable for any loss that you may incur as a result of someone else
          using your password or account, however, you could be held liable for
          losses incurred by SindhiPlex or another party due to someone else
          using your account or password.
        </P>
        <P>
          To be able to view any Premium Content, you will be asked to enter
          your user name and password after which you shall have to subscribe to
          any of the Plans as mentioned above and follow the payment procedure
          as prescribed here below.
        </P>
        <P>17. AVAILABILITY</P>
        <P>
          The availability of content(s) to view through SindhiPlex Site(s)/
          App(s) Service will change from time to time at the sole discretion of
          SindhiPlex. The quality of the display of the streaming video may vary
          from computer to computer, and device to device, and may be affected
          by a variety of factors, such as your location, the bandwidth
          available through and/or speed of your internet connection, and/ or
          quality of user's hardware. You are responsible for all internet
          access charges. Please check with your internet provider for
          information on possible internet data usage charges.
        </P>
        <P>
          Geographic Limitation: You may watch a video through the SindhiPlex
          Site(s)/ App(s) Service only in geographic locations where SindhiPlex
          offers the SindhiPlex Site(s)/ App(s) Service. The content that may be
          available to view will vary by geographic location. SindhiPlex may use
          technologies to verify your geographic location.
        </P>
        <P>18. SINDHIPLEX SITE(S)/ APP(S) SERVICE STREAMING SOFTWARE:</P>
        <P>
          The SindhiPlex Site/(s)/ App(s) Service streaming software is
          developed and designed to enable only streaming of content on the
          devices/platforms which are Service ready. These Site(s)/ App(s) may
          vary by device and medium, and functionalities may also differ between
          devices. This Site(s)/ App(s) is licensed to you pursuant to these
          Terms and solely for the purpose of using the SindhiPlex Site(s)/
          App(s) Service and for no other purpose whatsoever. SindhiPlex does
          not warrant the performance of the Site(s)/ App(s), including its
          continuing compatibility with the SindhiPlex Site(s)/ App(s). Any
          unauthorized use of the Site(s)/ App(s) is strictly prohibited and
          SindhiPlex reserves the right to not provide the Site(s)/ App(s)
          (including updates) to you at any time and to discontinue the ability
          to access the SindhiPlex Site(s)/ App(s) Service through such Site(s)/
          App(s) at any time, without prior or any notice. When you use an
          SindhiPlex Site(s)/ App(s) ready device, you expressly represent that
          you have the express authority to use such device and be liable for
          any transaction conducted through such device on the Site(s)/ App(s).
          The SindhiPlex Site(s)/ App(s) permits only streaming of the content
          available on the Site(s)/ App(s)and restricts the download and storage
          of such content, except the download functionality available on the
          App(s).
        </P>
        <P>19. BILLING</P>
        <P>
          By subscribing to the SindhiPlex Site(s)/ App(s) Service, you are
          expressly agreeing that SindhiPlex is authorized to charge you
          subscription fee as per your selected plan, any other fees for
          additional services you may purchase, and any applicable taxes in
          connection with your use of the SindhiPlex Site(s)/ App(s) Service
          through any such payment method/ instrument including but not limited
          to credit/debit card, net banking or other payment method/ instrument
          accepted by SindhiPlex (“Payment Method”) that you are provided. The
          Payment Methods will differ from platform to platform (operating
          systems). In the event your subscription expires, SindhiPlex reserves
          the right to auto-renew your subscription through your authorized
          Payment Method provided such Payment Method offers the service of
          auto-renewal. You hereby authorize us to effect such auto-renewal in
          the above mentioned scenario.
        </P>
        <P>
          In the event you desire to discontinue your auto-renewed subscription,
          you may refer to the FAQs or contact us at support@SindhiPlex.com in
          order to obtain assistance in this regard.
        </P>
        <P>
          As used in these Terms, “billing” shall indicate either a charge or
          debit, as applicable, against your Payment Method.
        </P>
        <P>
          The subscription fee will be billed at the beginning of your
          subscription and towards the end of each subscription term. We will
          notify you regarding such expiration. To see the commencement date for
          your next renewal period, go to the billing information section on
          your account page. If you change your Payment Method or subscription
          plan, this could result in changing the date on which you are billed.
          However, SindhiPlex will not be able to notify you of changes in any
          applicable taxes. All fees and charges are non-refundable and there
          are no refunds or credits for partially used periods and/or unwatched
          content. Any changes in the subscription plan opted for by you shall
          be effective only after the expiry of the then current subscription
          period for which you have been billed. Similarly, the services as per
          revised plan opted by you shall be effective only after the expiry of
          the then current subscription period. In case you require a change in
          the subscription plan and/or services prior to the expiry of the then
          current subscription period, then SindhiPlex shall not be under any
          obligation to refund or give credit for any portion of the
          subscription fee paid by you for the unexpired period. You will remain
          liable to SindhiPlex for all such amounts and all costs incurred by
          SindhiPlex in connection with the collection of these amounts,
          including, without limitation, collection agency fees, reasonable
          attorneys fees, and arbitration or court costs. You also understand
          and acknowledge that SindhiPlex only facilitates the third party
          payment gateway for processing of payment. This facility is managed by
          the third party payment gateway provider and you are required to
          follow all the terms and conditions of such third party payment
          gateway provider. You are responsible for the accuracy and
          authenticity of the information provided by you, including the bank
          account number/credit card details and the like. You agree and
          acknowledge that SindhiPlex shall not be liable and in no way be held
          responsible for any losses whatsoever, whether direct, indirect,
          incidental or consequential, including without limitation any losses
          due to delay in processing of payment instruction or any credit card
          fraud. You can contact us at support@SindhiPlex.com in case you
          require any assistance with regard to payment transfer on the Site(s)/
          App(s) and the same could be forwarded to the concerned third party
          payment gateway provider for the redressal. You consent that
          SindhiPlex, at its sole discretion, shall be entitled to revise the
          fees (including but not limited to charging for the free content and
          vice versa) associated with the content(s) hosted on Site(s)/ App(s).
          You may cancel your membership at any time; however, there are no
          refunds or credits for partially used periods. It must be noted that
          subscription for SindhiPlex Site(s)/ App(s)Services shall not provide
          as an entry into any contests or events of SindhiPlex.
        </P>
        <P>20. MISCELLANEOUS</P>
        <P>
          The Privacy Policy (as provided on the Site(s)/ Apps(s)), and any
          other documents, instructions, etc. included on the Site(s)/ Apps(s)
          shall be read in conjunction with and shall form an integral part of
          these Terms of Use.
        </P>
        <P>
          21. PLEASE NOTE: IF YOU STILL PROCEED TO ACCESS THE APP(S) AND SHARE
          THE PERSONAL INFORMATION WITH SINDHIPLEX, YOU SOLELY SHALL BE
          RESPONSIBLE FOR ANY KIND OF LEGAL OR OTHER ACTIONS THAT SHALL TAKE
          PLACE AND SINDHIPLEX SHALL NOT BE RESPONSIBLE FOR ANY ACTIONS IN ANY
          MANNER WHATSOEVER.
        </P>
      </Col>
    </CenterRow>
  );
}

export default TermsOfService;

const H1 = styled.h1`
  color: gold;
  font-size: 16px;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 24px;
`;

const P = styled.p`
  color: gold;
  font-size: 16px;
  text-align: left;
`;

const CenterRow = styled(Row)`
  justify-content: center;
  margin-bottom: 30px;
`;
