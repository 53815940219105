import React from "react";

import { Col, Row } from "antd";
import styled from "styled-components";

function RefundPolicy() {
  return (
    <CenterRow>
      <Col md={12}>
        <P>Refund Policy</P>
        <P>
          This Policy applies to SindhiPlex Site/ App platforms including
          without limitation www.sindhiplex.com (https://www.sindhiplex.com) and
          other related Site/s or App/s, mobile applications and other online
          features each a “Site/s or App/s”. We have provided extensive
          information for you to view the packages before choosing to subscribe
          to us. If you have any questions or reservations, please contact us at
          support@sindhiplex.com prior to subscribing to our services.
        </P>

        <P>
          We, being the service providers for contents available through SITE or
          APP, where you are expected to view packages of your choice after
          being paid for subscription; unfortunately, all fees to Sai Digital
          Media Pvt. Ltd. for such services are non-refundable.
        </P>
        <P>
          In case, because of any technical glitch at the time of online
          transaction, the transaction does not occur, the amount in process of
          transfer by default goes back into your bank account, automatically
          through Payment Gateway.
        </P>

        <P>CHARGEBACKS</P>
        <P>
          If we receive a chargeback or payment dispute from a credit card
          company or bank, your service and/or subscription will be suspended
          without notice. Applicable chargeback fee will be issued to recover
          fees passed on to us by the credit company, plus any outstanding
          balances accrued as a result of the chargeback(s) must be paid in full
          before service is restored. Instead of issuing a chargeback, contact
          us to address any billing issues. Requesting a chargeback or opening
          any sort of dispute for a valid charge from us is fraud, and is never
          an appropriate or legal means of obtaining a refund.
        </P>
      </Col>
    </CenterRow>
  );
}

export default RefundPolicy;

const P = styled.p`
  color: gold;
  font-size: 16px;
`;

const CenterRow = styled(Row)`
  justify-content: center;
  margin-bottom: 30px;
`;
