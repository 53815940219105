import React, { useRef } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { message } from 'antd';

import logo_poster from '../../media/SINDHIPLEX_COMING_SOON.gif';
import favicon from '../../media/favicon.ico';

function ComingSoon() {

  const videoRef = useRef(null);

  const handlePlayVideo = () => {
    if(videoRef.current.muted){
      videoRef.current.muted = false;
    }
  }

  const displayMessage = () => {
    message.success('Click to play audio.');
  }

  return (
    <>
      <Helmet>
        <title>Sindhiplex | Coming Soon</title>
        <link id="favicon" rel="icon" href={favicon} type="image/x-icon"/>
      </Helmet>
      <Video
        autoPlay
        loop
        muted
        poster={logo_poster}
        ref={videoRef}
        onClick={handlePlayVideo}
        onPlay={displayMessage}
      >
        <source src={logo_poster} type='video/mp4' />
      </Video>
    </>
  );
}

export default ComingSoon;

const Video = styled.video`
  position: fixed;
  width: 100%;
  height: 100%;
`;
