import React from "react";

import { Col, Row } from "antd";
import styled from "styled-components";

function About() {
  return (
    <CenterRow>
      <Col md={12}>
        <P>
          Finally 1st ever Sindhi OTT platform is here where you will get all
          sindhi content like Music shows, Web Series, Crime Shows, Movies not
          just this even Sindhi learning show in a Unique way, We are an example
          of Atmanirbhar Bharat as we didn't had any sindhi television channel
          so.
        </P>
        <P>
          We made our own sindhi OTT app as now Television is outdated get
          yourself updated with sindhi app i e SINDHIPLEX Subscribe now Sindhi
          Aayo Sindhi Sadayo Sindhyat Wadhayao Jai Jhulelal.
        </P>
      </Col>
    </CenterRow>
  );
}

export default About;

const P = styled.p`
  color: gold;
  font-size: 16px;
`;

const CenterRow = styled(Row)`
  justify-content: center;
  margin-bottom: 30px;
`;
