import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import styled from "styled-components";

import "antd/dist/antd.css";
import "./App.css";

import favicon from "./media/favicon.ico";
import ComingSoon from "./components/ComingSoon/ComingSoon";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import TermsOfService from "./pages/TermsOfService";
import RefundPolicy from "./pages/RefundPolicy";

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Sindhiplex</title>
        <link id="favicon" rel="icon" href={favicon} type="image/x-icon" />
      </Helmet>
      <header className="App-header">
        <Router>
          <Nav>
            <Anchor to="/">Home</Anchor>
            <Anchor to="/about">About</Anchor>
            <Anchor to="/contact-us">Contact Us</Anchor>
            <PrivacyLink href="https://privacy-policy.sindhiplex.com">
              Privacy Policy
            </PrivacyLink>
            <Anchor to="/terms-of-service">Terms of Service</Anchor>
            <Anchor to="/refund-policy">Refund Policy</Anchor>
          </Nav>
          <Switch>
            <Route path="/" exact>
              <ComingSoon />
            </Route>
            <Route path="/about" exact>
              <About />
            </Route>
            <Route path="/contact-us" exact>
              <ContactUs />
            </Route>
            <Route path="/terms-of-service" exact>
              <TermsOfService />
            </Route>
            <Route path="/refund-policy" exact>
              <RefundPolicy />
            </Route>
          </Switch>
        </Router>
      </header>
    </div>
  );
}

export default App;

const Nav = styled.nav`
  position: fixed;
  color: red;
  z-index: 9999;
  bottom: 0px;
  background: black;
`;

const Anchor = styled(Link)`
  color: red;
  margin: 0 10px;
  font-size: 16px;

  &:hover {
    color: gold;
  }
`;

const PrivacyLink = styled.a`
  color: red;
  margin: 0 10px;
  font-size: 16px;

  &:hover {
    color: gold;
  }
`;
