import React from "react";

import { Col, Row } from "antd";
import styled from "styled-components";

function ContactUs() {
  return (
    <>
      <CenterRow>
        <Col lg={24}>
          <P style={{ textAlign: "center" }}>
            For any inquery, support and feedback connect us at.
          </P>
        </Col>
        <Col lg={12}>
          <P style={{ textAlign: "right" }}>Email:-</P>
        </Col>
        <Col lg={12}>
          <P style={{ textAlign: "left", marginLeft: "30px" }}>
            contact@saidigitalbroadcast.com
          </P>
        </Col>
      </CenterRow>
      <CenterRow>
        <Col lg={12}>
          <P style={{ textAlign: "right" }}>Registered Address: -</P>
        </Col>
        <Col lg={12}>
          <P style={{ textAlign: "left", marginLeft: "30px" }}>
            Sai Digital Broadcast Media Pvt. Ltd <br />
            B/444, 4th floor. Oshiwara Industrial Centre Premises CHSL. New Link
            <br />
            Road. Goregaon west. <br />
            Mumbai - 400104.
          </P>
        </Col>
      </CenterRow>
    </>
  );
}

export default ContactUs;

const P = styled.p`
  color: gold;
  font-size: 16px;
`;

const CenterRow = styled(Row)`
  justify-content: center;
  margin-bottom: 30px;
`;
